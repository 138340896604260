// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.current-role {
  width: 98px;
  height: 24px;
  font-family: Muli;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.26px;
  color: var(--black);
}

.auth-role-form {
  margin-left: 5px;
}

.icon-arrow {
  color: #006937;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/role-picker/role-picker.component.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;AADF;;AAIA;EACE,gBAAA;AADF;;AAIA;EACE,cCHa;ADEf","sourcesContent":["@import '../../../variables.scss';\n\n.current-role {\n  width: 98px;\n  height: 24px;\n  font-family: Muli;\n  font-size: 16px;\n  font-weight: 900;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.5;\n  letter-spacing: -0.26px;\n  color: var(--black);\n}\n\n.auth-role-form {\n  margin-left: 5px;\n}\n\n.icon-arrow {\n  color: $jungle-green;\n}\n","// Brand Identity Colors\n$brand: (\n  'blue': #05347a,\n  'dark-grey': #53565a,\n  'black': #000000,\n  'white': #ffffff,\n  'green': #008751,\n  'light-green': #8cc63e,\n  'light-blue': #1b85ea,\n  'light-grey': #7e8283,\n  'light-grey-2': #e6e6e6,\n  'light-grey-3': #d4d4d4,\n  'light-grey-7': #a1a1a1,\n  'light-grey-9': #7d7d7d,\n);\n\n// Application Colors\n$jungle-green: #006937;\n$deep-green: #004725;\n$forest-green: #26a532;\n$light-green: #0047256e;\n$very-light-pink: #cdcdcd;\n$brown-grey: #929292;\n$deep-orange: #d65600;\n$red: #d60600;\n$blue: #3f71d3;\n$charcoal: #464646;\n$charcoal-light: #969696;\n$magnolia: #f5f4f7;\n$white: #fff;\n\n// Card\n$default-slot-width: 192px;\n$panel-mode-small-column-width: 160px;\n$panel-mode-medium-column-width: 192px;\n$panel-mode-large-column-width: 236px;\n$panel-mode-extra-large-column-width: 270px;\n$panel-mode-2-extra-large-column-width: 300px;\n$panel-mode-3-extra-large-column-width: 320px;\n\n@function brand-color($color) {\n  @return map-get($brand, $color);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
