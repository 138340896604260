// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-component {
  position: relative;
}

.filter-input {
  border: solid 1px #cdcdcd;
  height: 48px;
  border-radius: 24px;
  width: 100%;
  background-color: white;
  font-size: 16px;
  padding-right: 40px;
  padding-left: 16px;
}

.filter-icon {
  color: #006937;
  position: absolute;
  top: 25%;
  right: 5%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/filter-input/filter-input.component.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;AADF;;AAIA;EACE,yBAAA;EACA,YAAA;EACA,mBAAA;EACA,WAAA;EACA,uBAAA;EACA,eAAA;EACA,mBAAA;EACA,kBAAA;AADF;;AAIA;EACE,cCDa;EDEb,kBAAA;EACA,QAAA;EACA,SAAA;AADF","sourcesContent":["@import '../../../variables.scss';\n\n.filter-component {\n  position: relative;\n}\n\n.filter-input {\n  border: solid 1px $very-light-pink;\n  height: 48px;\n  border-radius: 24px;\n  width: 100%;\n  background-color: white;\n  font-size: 16px;\n  padding-right: 40px;\n  padding-left: 16px;\n}\n\n.filter-icon {\n  color: $jungle-green;\n  position: absolute;\n  top: 25%;\n  right: 5%;\n}\n","// Brand Identity Colors\n$brand: (\n  'blue': #05347a,\n  'dark-grey': #53565a,\n  'black': #000000,\n  'white': #ffffff,\n  'green': #008751,\n  'light-green': #8cc63e,\n  'light-blue': #1b85ea,\n  'light-grey': #7e8283,\n  'light-grey-2': #e6e6e6,\n  'light-grey-3': #d4d4d4,\n  'light-grey-7': #a1a1a1,\n  'light-grey-9': #7d7d7d,\n);\n\n// Application Colors\n$jungle-green: #006937;\n$deep-green: #004725;\n$forest-green: #26a532;\n$light-green: #0047256e;\n$very-light-pink: #cdcdcd;\n$brown-grey: #929292;\n$deep-orange: #d65600;\n$red: #d60600;\n$blue: #3f71d3;\n$charcoal: #464646;\n$charcoal-light: #969696;\n$magnolia: #f5f4f7;\n$white: #fff;\n\n// Card\n$default-slot-width: 192px;\n$panel-mode-small-column-width: 160px;\n$panel-mode-medium-column-width: 192px;\n$panel-mode-large-column-width: 236px;\n$panel-mode-extra-large-column-width: 270px;\n$panel-mode-2-extra-large-column-width: 300px;\n$panel-mode-3-extra-large-column-width: 320px;\n\n@function brand-color($color) {\n  @return map-get($brand, $color);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
