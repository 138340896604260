import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/auth/auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SystemMaintenanceComponent } from './system-maintenance/system-maintenance.component';
import { SystemMaintenanceGuard } from './system-maintenance/system-maintenance.guard';
import { LogoutComponent } from './logout/logout.component';
import { LoginErrorComponent } from './login-error/login-error.component';
import { ExternalNcrCreationComponent } from './external-ncr-creation/external-ncr-creation.component';
import { OfferStatus } from './exterior-wash-offer-response/exterior-wash-offer-response.model';
import { ExteriorWashOfferResponseComponent } from './exterior-wash-offer-response/exterior-wash-offer-response.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './dashboard/home/home.component';
import { homeRedirectGuard } from './guards/home-redirect.guard';

const routes: Routes = [
  {
    path: SystemMaintenanceComponent.route,
    canActivate: [SystemMaintenanceGuard],
    component: SystemMaintenanceComponent,
  },
  {
    path: DashboardComponent.route,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'home',
    canActivate: [AuthGuard, homeRedirectGuard],
    component: HomeComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'external-ncr',
    component: ExternalNcrCreationComponent,
  },
  {
    path: 'exterior-wash-accept',
    component: ExteriorWashOfferResponseComponent,
    data: {
      offerResponseStatus: OfferStatus.ACCEPT,
    },
  },
  {
    path: 'exterior-wash-decline',
    component: ExteriorWashOfferResponseComponent,
    data: {
      offerResponseStatus: OfferStatus.DECLINE,
    },
  },
  {
    path: 'login-depot',
    component: LoginComponent,
    data: {
      shouldUseGetUserInfo: true,
    },
  },
  {
    path: 'login-error',
    component: LoginErrorComponent,
  },
  {
    path: '**',
    redirectTo: '/home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
