import { Injectable, Injector, ViewContainerRef } from '@angular/core';
import { ReplaySubject, BehaviorSubject, from } from 'rxjs';
import { OverlayRef, Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { CONTAINER_DATA } from './wash-details-overlay/tokens';
import { AuthService } from '../../core/auth/auth.service';
import { catchError, finalize, switchMap, take, tap } from 'rxjs/operators';
import {
  Card,
  Bay,
  Shift,
  Operator,
  ManualSchedulePayload,
  BlockingMessagesEnum,
  MissedNeedByTimeReport,
  ManualStartStopData,
} from './schedule.model';
import { Slot, AgendaBay, SlotStatuses } from './agenda/agenda.model';
import { APIService } from 'src/app/core/API.service';
import { get } from 'lodash';
import { WashRequestStatus } from '../wash-list/wash-list.model';
import { API } from '@aws-amplify/api';
import { GraphQLService } from 'src/app/core/graphql.service';
import { TimeService } from '../../core/time.service';
import { WashListService } from '../wash-list/wash-list.service';
import { MissedNeedByTimeReportService } from './missed-need-by-time-report/missed-need-by-time-report.service';
import { StartStopConfirmationService } from './start-stop-confirmation/start-stop-confirmation.service';
import { ManualStartStopReasonService } from './start-stop-confirmation/manual-start-stop-reason/manual-start-stop-reason.service';
import { WashRequestDetail } from './wash-details-overlay/wash-details-overlay.model';
import { OperatorsDialogService } from './operators-dialog/operators-dialog.service';
import { LoadingDialogService } from 'src/app/shared/loading-dialog.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

interface DataStore {
  bays: Bay[];
  shifts: Shift[];
  cards: Card[];
  isMoveMode: boolean;
  operators: Operator[];
}

export interface BlockingReasonMessage {
  blockingReasonMessage: string;
  reasons?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  private readonly _cardPropertiesToKeep = ['canStopAfter'];

  public dataStore: DataStore = {
    bays: [],
    shifts: [],
    cards: [],
    isMoveMode: null,
    operators: [],
  };

  // tslint:disable-next-line: variable-name
  private _selectedCard: Card;
  get selectedCard(): Card {
    return this._selectedCard;
  }

  overlayRef: OverlayRef;
  overlayCardId: string;
  overlayContainer: ComponentType<any>;

  private defaultHeaders: any;
  private nonSchedulableStatus = [
    WashRequestStatus.Completed,
    WashRequestStatus.OnHold,
  ];

  // tslint:disable-next-line: variable-name
  private _bays = new ReplaySubject<Bay[]>();
  readonly bays = this._bays.asObservable();

  // tslint:disable-next-line: variable-name
  private _shifts = new ReplaySubject<Shift[]>();
  readonly shifts = this._shifts.asObservable();

  // tslint:disable-next-line: variable-name
  private _cards = new ReplaySubject<Card[]>();
  readonly cards = this._cards.asObservable();

  // tslint:disable-next-line: variable-name
  private _operators = new ReplaySubject<Operator[]>();
  readonly operators = this._operators.asObservable();

  // tslint:disable-next-line: variable-name
  private _isMoveMode = new BehaviorSubject<boolean>(this.dataStore.isMoveMode);
  readonly isMoveMode = this._isMoveMode.asObservable();

  // tslint:disable-next-line: variable-name
  private _firstSlotTime: number;

  get firstSlotTime(): number {
    return this._firstSlotTime || Math.round(Date.now() / 1000);
  }

  constructor(
    private api: APIService,
    public overlay: Overlay,
    private injector: Injector,
    private authService: AuthService,
    private graphQLService: GraphQLService,
    private timeService: TimeService,
    private washListService: WashListService,
    private missedNeedByTimeReportService: MissedNeedByTimeReportService,
    private startStopConfirmationService: StartStopConfirmationService,
    private manualStartStopReasonService: ManualStartStopReasonService,
    private operatorsDialogService: OperatorsDialogService,
    private toastr: ToastrService,
    private loadingDialogService: LoadingDialogService
  ) {
    this.defaultHeaders = {
      'Content-Type': 'application/json',
      'x-ontrax-identity': `${this.authService.user.username};${this.authService.user.currentRoleAcronym}`,
    };

    // Listen to changes triggered in the backend
    this.graphQLService
      .listenToAgendaChangesByTerminal(this.authService.user.currentTerminal.id)
      .subscribe((res) => {
        const updatedCards = get(
          res,
          ['value', 'data', 'onAgendaChanges', 'cards'],
          []
        );
        const cards = updatedCards
          .filter(
            (item) =>
              item.terminal === this.authService.user.currentTerminal.key
          )
          .map((item) => new Card(item));
        if (cards.length === 0) {
          return;
        }
        this.updateCardsList(cards);
      });

    this.graphQLService
      .listenToShiftChangesByTerminal(this.authService.user.currentTerminal.id)
      .subscribe(() => {
        this.loadInitialData();
      });
  }

  /**
   * Group sequential shifts based on the start time and finish time of the shifts.
   * Each group will have shifts that are sequential.
   * @param shifts Shift
   * @returns Shifts grouped in groups of sequential shifts
   */
  static getSequentialShifts(shifts: Shift[]): Shift[][] {
    const groupedShifts = [];
    let currentShift = shifts[0];
    let currentShiftIndex = 0;

    for (let i = 1; i < shifts.length; i++) {
      const nextShift = shifts[i];
      if (currentShift.finishTime === nextShift.startTime - 60) {
        currentShift = nextShift;
      } else {
        groupedShifts.push(shifts.slice(currentShiftIndex, i));
        currentShiftIndex = i;
        currentShift = nextShift;
      }
    }

    groupedShifts.push(shifts.slice(currentShiftIndex, shifts.length));
    return groupedShifts;
  }

  getCardsInSequentialShifts(shifts: Shift[], bayId?: string): Card[] {
    const sortedShift = shifts.sort((a, b) => a.startTime - b.startTime);

    const earliestShift = sortedShift[0];
    const lastShift = sortedShift[sortedShift.length - 1];

    const inShiftCards = this.dataStore.cards.filter((item) =>
      bayId
        ? item.bayId === bayId
        : true &&
          (item.startTime >= earliestShift.startTime ||
            item.finishTime >= earliestShift.startTime) &&
          (item.finishTime <= lastShift.finishTime ||
            item.startTime <= lastShift.finishTime)
    );

    return inShiftCards;
  }

  detachOverlay() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }

  createInjector<DataT>(data: DataT, overlayRef: OverlayRef): Injector {
    const providers = [
      { provide: OverlayRef, useValue: overlayRef },
      { provide: CONTAINER_DATA, useValue: data },
    ];
    return Injector.create({ parent: this.injector, providers });
  }

  private getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    return new OverlayConfig({
      positionStrategy,
      hasBackdrop: true,
      panelClass: 'popover-panel-details',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });
  }

  displayOperatorsDialog<T>({ card }: { card: Card }, cardInProgress?: Card) {
    this.overlayCardId = card.id;
    let washRequestId = card.id;
    let currentCardInProgress;

    if (cardInProgress && cardInProgress.id !== washRequestId) {
      currentCardInProgress = cardInProgress;
    }

    this.washListService._operators.pipe(take(1)).subscribe(() => {
      return this.operatorsDialogService
        .openDialog(
          this.washListService.dataStore.operators,
          this.washListService.dataStore.recentOperators
        )
        .then((operatorId) => {
          this.overlayCardId = null;
          if (!operatorId) return;
          this.start(washRequestId, operatorId, currentCardInProgress);
        });
    });
  }

  displayOverlay<T>(
    { card, isBayOccupied }: { card: Card; isBayOccupied: boolean },
    viewContainerRef: ViewContainerRef,
    container: ComponentType<T>,
    cardInProgress?: Card
  ) {
    const overlayConfig = this.getOverlayConfig();
    this.overlayRef = this.overlay.create(overlayConfig);

    // Attach ComponentPortal to PortalHost
    this.overlayRef.attach(
      new ComponentPortal(
        container,
        viewContainerRef,
        this.createInjector(
          {
            card,
            isBayOccupied,
            operators: this.dataStore.operators,
            cardInProgress,
          },
          this.overlayRef
        )
      )
    );
  }

  async loadInitialData() {
    // Only quala worker can access schedule service
    // Only quala worker has a terminal linked to its user data
    const terminalId = this.authService.user.currentTerminal.id;
    const path = '/schedule';
    const httpOptions = {
      headers: this.defaultHeaders,
      body: {
        terminal: terminalId,
      },
    };

    this.dataStore.operators = [];
    this._operators.next(this.dataStore.operators);
    const response: any = await API.post('PortalAPI', path, httpOptions);

    this._firstSlotTime = response.firstSlotTime;
    this.dataStore.bays = response.bays;
    this._bays.next(Object.assign({}, this.dataStore).bays);
    this.dataStore.shifts = response.shifts;
    this._shifts.next(Object.assign({}, this.dataStore).shifts);
    const cards = (response.cards || [])
      // Make sure only scheduled requests not completed or hold
      .filter((item) => !this.nonSchedulableStatus.includes(item.status))
      .map((item) => new Card(item));

    this.updateCardsList(cards);
  }

  setMoveMode(value: boolean) {
    this.dataStore.isMoveMode = value;
    this._isMoveMode.next(this.dataStore.isMoveMode);
  }

  selectCard(card: Card) {
    this.setMoveMode(!!card);
    this._selectedCard = card;
  }

  isPrioritizedBp(bay: AgendaBay, card) {
    const boassoBPIds = [
      'DFDFCF24FD454359845EF5F935B06E21',
      'F90A2887020D4FFBB85BDFA1E12F8514',
      '0FCDD877757F40379AF8B0735C7A3EE0',
      '446F865B694E48F6A50E4DAC8651905B',
      '097E37E87F574F0884A9201CFD8FB38E',
    ];

    if (!bay.prioritizedBp || !card.tankOwnerId || !card.operatedById) {
      return true;
    }

    const foundTankOwnerOrBP =
      boassoBPIds.some((item) => card.tankOwnerId.includes(item)) ||
      boassoBPIds.some((item) => card.operatedById.includes(item));

    return foundTankOwnerOrBP;
  }

  blockReasonToCardMovementToSlot(
    bay: AgendaBay,
    slot: Slot,
    card: Card
  ): BlockingReasonMessage {
    const sequentialShifts = ScheduleService.getSequentialShifts(
      this.dataStore.shifts
    );
    const shiftGroup = sequentialShifts
      .find((group) =>
        group.some((shift) => shift.startTime === bay.shift.startTime)
      )
      .sort((a, b) => a.startTime - b.startTime);
    const firstShift = shiftGroup[0];
    const lastShift = shiftGroup[shiftGroup.length - 1];

    const inShiftCards = this.getCardsInSequentialShifts(shiftGroup, bay.id);

    if (bay.prioritizedBp && !this.isPrioritizedBp(bay, card)) {
      return { blockingReasonMessage: BlockingMessagesEnum.EXCLUSIVE_BAY };
    }
    // If there is no selected card do not allow the move operation
    if (!card) {
      return { blockingReasonMessage: BlockingMessagesEnum.GENERIC };
    }

    // If the slot selected is blocked do not allow the reschedule
    if (slot.status === SlotStatuses.BLOCKED) {
      return { blockingReasonMessage: BlockingMessagesEnum.OVERLAPPING };
    }

    // If the slot selected is out of shift do not allow the reschedule
    if (slot.status === SlotStatuses.OUT_SHIFT) {
      return { blockingReasonMessage: BlockingMessagesEnum.OUT_OF_SHIFT };
    }

    // Calculate the time that the card is occupying in the original shift
    const actualTimeInShift =
      (Math.min(card.finishTime, lastShift.originalShiftTime.finish) -
        Math.max(card.startTime, firstShift.originalShiftTime.start)) /
      60;

    const isSlotInTheSameShiftGroup =
      slot.startTime >= firstShift.startTime &&
      slot.finishTime <= lastShift.finishTime;

    {
      const newStartTime = slot.startTime;
      const newFinishTime = slot.startTime + card.duration * 60;
      const slotsToFill = bay.slots.filter(
        (baySlot) =>
          baySlot.startTime >= newStartTime &&
          baySlot.finishTime <= newFinishTime
      );

      // If some of the slots to be occupied is blocked
      if (
        slotsToFill.some((baySlot) => baySlot.status === SlotStatuses.BLOCKED)
      ) {
        return { blockingReasonMessage: BlockingMessagesEnum.OVERLAPPING };
      }
    }

    // If the slot selected is empty and before the card to be moved no more validation is needed
    const cardDate = new Date(card.startTime * 1000).getUTCDate();
    const slotDate = new Date(slot.startTime * 1000).getUTCDate();
    const passValidations =
      card.bayId === bay.id &&
      card.startTime >= slot.startTime &&
      !card.isOutOfShift &&
      slot.status === SlotStatuses.EMPTY &&
      isSlotInTheSameShiftGroup;

    // If the move is marked to pass there is no need for further validations
    if (!passValidations) {
      if (
        // If the slot selected is empty and before the card to be moved but the card is out of shift
        // check if there is time enough considering only the shift time occupied by the card
        card.bayId === bay.id &&
        card.startTime >= slot.startTime &&
        card.isOutOfShift &&
        slot.status === SlotStatuses.EMPTY &&
        isSlotInTheSameShiftGroup
      ) {
        if (slot.availableTimeBag + actualTimeInShift < card.duration) {
          return { blockingReasonMessage: BlockingMessagesEnum.UNSUITABLE };
        }
      } else if (
        // If the slot selected is within the card to be moved and in the same bay
        // check if there is room for the card considering the time available
        // from the slot to the end of the card that is being moved
        bay.id === card.bayId &&
        slot.startTime >= card.startTime &&
        slot.finishTime <= card.finishTime
      ) {
        const isSlotNotInTheSameShiftAsStart =
          cardDate !== slotDate && card.isATwoDaysSchedule;
        const fromSlotToFinish =
          card.finishTime -
          (isSlotNotInTheSameShiftAsStart ? slot.startTime : slot.finishTime);
        const timeAvailable = slot.availableTimeBag + fromSlotToFinish / 60;

        // If there is no room for the card to be moved do not allow the operation
        if (card.duration > timeAvailable) {
          return { blockingReasonMessage: BlockingMessagesEnum.UNSUITABLE };
        }
      } else if (slot.status !== SlotStatuses.EMPTY) {
        // If the selected slot is not empty
        // Check if there is room for the card occupying the slot to be moved
        const cardShifted = inShiftCards.find(
          (bayCard) => bayCard.finishTime >= slot.finishTime
        );
        const cardTimeToBeShifted =
          (slot.finishTime - cardShifted.startTime) / 60;
        const timeAvailable =
          slot.availableTimeBag +
          (bay.id === card.bayId &&
          card.startTime >= slot.startTime &&
          isSlotInTheSameShiftGroup
            ? actualTimeInShift
            : 0) -
          cardTimeToBeShifted;

        // If there is no room for the shifted card do not allow the operation
        if (card.duration > timeAvailable) {
          return { blockingReasonMessage: BlockingMessagesEnum.UNSUITABLE };
        }
      } else if (
        // Otherwise if the slot is empty either ahead or before the card to be moved
        // check if there is available time for the card to be allocate in the day
        // by checking the slot 'availableTimeBag' property
        card.duration > slot.availableTimeBag
      ) {
        return { blockingReasonMessage: BlockingMessagesEnum.UNSUITABLE };
      }
    }

    // After previous validation, if it is a walkup or downtime, allow the move
    if (card.cardType === 'walkup' || card.cardType === 'downtime') {
      return { blockingReasonMessage: null };
    }

    // If it is a scheduled card, verify compatible bay
    if (card.bayId === bay.id || card.compatibleBays.includes(bay.id)) {
      return { blockingReasonMessage: null };
    }

    const failedValidationToReasonMap = {
      kosherCompatible: 'Not Kosher',
      matchContainKeywords: '"Contains" - No Match',
      matchNotContainKeywords: '"Not Contains" - Match',
      specialPrepCompatible: 'Not Special Prep',
    };

    const foundIncompatibleBays = card.incompatibleBays.find(
      (incompatibleBay) => incompatibleBay && bay.id === incompatibleBay.bayId
    );

    const failedReasons = foundIncompatibleBays?.failedValidations?.map(
      (failedValidation) =>
        failedValidationToReasonMap[failedValidation as string]
    );

    const blockingReasonMessage = {
      blockingReasonMessage: BlockingMessagesEnum.INCOMPATIBLE,
      ...(failedReasons && { reasons: failedReasons }),
    };

    return blockingReasonMessage;
  }

  canMoveCardTo(bay: AgendaBay, slot: Slot): BlockingReasonMessage {
    return this.blockReasonToCardMovementToSlot(bay, slot, this._selectedCard);
  }

  hold(id: string) {
    const path = '/wash-request/hold';
    const httpOptions = {
      headers: this.defaultHeaders,
      body: { id },
    };
    const scheduledRequest = this.dataStore.cards.find(
      (item) => item.id === id
    );

    if (scheduledRequest) {
      this.updateCardsList([
        {
          ...scheduledRequest,
          startTime: 0,
          finishTime: 0,
          status: WashRequestStatus.OnHold,
          cardType: 'remove',
        },
      ]);
    }

    return API.put('PortalAPI', path, httpOptions);
  }

  start(id: string, operatorId: string, cardInProgress?: Card) {
    let inProgressWashRequestId;
    if (cardInProgress) {
      inProgressWashRequestId = cardInProgress.id;
    }

    const path = '/wash-request/start';
    const httpOptions = {
      headers: this.defaultHeaders,
      body: {
        id,
        operatorId,
        inProgressWashRequestId,
      },
    };

    const scheduledRequest = this.dataStore.cards.find(
      (item) => item.id === id
    );
    if (scheduledRequest && cardInProgress) {
      scheduledRequest.linked = cardInProgress;
    }
    if (scheduledRequest) {
      this.updateCardsList([
        {
          ...scheduledRequest,
          canStopAfter: this.timeService.getNowAsUTC(),
          status: WashRequestStatus.Started,
          cardType: 'progress',
          availableActionsStart: false,
          availableActionsPause: true,
          availableActionsHold: true,
          availableActionsStop: true,
        },
      ]);
    }

    return API.put('PortalAPI', path, httpOptions);
  }

  pause(id: string) {
    const path = '/wash-request/pause';
    const httpOptions = {
      headers: this.defaultHeaders,
      body: {
        id,
      },
    };

    const scheduledRequest = this.dataStore.cards.find(
      (item) => item.id === id
    );
    if (scheduledRequest) {
      this.updateCardsList([
        {
          ...scheduledRequest,
          status: WashRequestStatus.Paused,
          cardType: 'progress',
          availableActionsStart: true,
          availableActionsPause: false,
          availableActionsHold: true,
          availableActionsStop: false,
        },
      ]);
    }

    return API.put('PortalAPI', path, httpOptions);
  }

  async completeRequest(
    id: string,
    confinedSpaceEntryData,
    manualStartStopData?: ManualStartStopData,
    missedNeedByTimeReport?: MissedNeedByTimeReport
  ) {
    this.loadingDialogService.openDialog('Completing request...');
    let apiResponse;

    try {
      const {
        confinedEntry,
        workPerformedBy,
        confinedEntryType,
        confinedEntryFiles,
      } = confinedSpaceEntryData;
      const path = '/wash-request/complete';
      const httpOptions = {
        headers: this.defaultHeaders,
        body: {
          id,
          missedNeedByTimeReport,
          manualStartStopData,
          confinedEntry,
          workPerformedBy,
          confinedEntryType,
          confinedEntryFiles,
        },
      };

      const scheduledRequest = this.dataStore.cards.find(
        (item) => item.id === id
      );
      if (scheduledRequest) {
        this.updateCardsList([
          {
            ...scheduledRequest,
            startTime: 0,
            finishTime: 0,
            status: WashRequestStatus.Completed,
            cardType: 'remove',
          },
        ]);
      }

      apiResponse = await API.put('PortalAPI', path, httpOptions);

      await apiResponse;
    } catch (err) {
      this.loadingDialogService.closeDialog();
      throw err;
    }

    this.loadingDialogService.closeDialog();
    return apiResponse;
  }

  moveCards(cards: Card[]) {
    this.setMoveMode(false);
    cards.map((card) => {
      if (card.cardType.includes('downtime')) {
        delete card.reason;
      }
    });

    this.changeAgenda(cards, this.authService.user.currentTerminal.id);
    this.updateCardsList(cards);
  }

  async manualReschedule(manualSchedule: ManualSchedulePayload) {
    const path = '/manual-schedule';
    const httpOptions = {
      headers: this.defaultHeaders,
      body: manualSchedule,
    };

    await API.put('PortalAPI', path, httpOptions);
    return this.loadInitialData();
  }

  changeAgenda(cards: Card[], terminal: string) {
    const path = '/wash-request/reschedule';
    const httpOptions = {
      headers: this.defaultHeaders,
      body: {
        cards,
        terminal,
      },
    };

    return API.put('PortalAPI', path, httpOptions);
  }

  linkCards(targetCard: Card) {
    const path = '/wash-request/link';
    const httpOptions = {
      headers: this.defaultHeaders,
      body: {
        defaultCardId: targetCard.id,
        linkedCardId: this._selectedCard.id,
      },
    };

    return from(API.put('PortalAPI', path, httpOptions)).pipe(
      tap(() => {
        targetCard.linked = this._selectedCard;
      })
    );
  }

  updateBays(bays: Bay[]) {
    this.dataStore.bays = bays;
    this._bays.next(Object.assign({}, this.dataStore).bays);
  }

  isBayOccupied(bayId: string) {
    const bay = this.dataStore.bays.find((item) => item.id === bayId);
    return bay && bay.isOccupied;
  }

  async cancelMaintenance(id: string) {
    // Remove from card list
    const index = this.dataStore.cards.findIndex((item) => item.id === id);
    if (index >= 0) {
      const path = '/schedule/maintenance/cancel';
      const httpOptions = {
        headers: this.defaultHeaders,
        body: {
          scheduleId: id,
          terminalId: this.authService.user.currentTerminal.id,
        },
      };
      API.post('PortalAPI', path, httpOptions);
      this.dataStore.cards.splice(index, 1);
      this._cards.next(Object.assign({}, this.dataStore).cards);
    }
  }

  private updateCardInStore(cardInStore: Card, updatedCard: Card) {
    const newCard = updatedCard;
    this._cardPropertiesToKeep.forEach((property) => {
      updatedCard[property] = cardInStore[property] || updatedCard[property];
    });

    return newCard;
  }

  private updateCardsList(cards: Card[]) {
    cards.forEach((card) => {
      const updatedCardIndex = this.dataStore.cards.findIndex(
        (item: Card) => item.id === card.id
      );

      if (updatedCardIndex >= 0) {
        this.dataStore.cards[updatedCardIndex] = this.updateCardInStore(
          this.dataStore.cards[updatedCardIndex],
          card
        );
      } else {
        this.dataStore.cards.push(card);
      }
    });

    this._cards.next(Object.assign({}, this.dataStore).cards);
  }

  updateCardsOnLink(updatedLinkedTargetCard: Card) {
    const linkedTargetCardIndex = this.dataStore.cards.findIndex(
      (item) => item.id === updatedLinkedTargetCard.id
    );
    this.dataStore.cards[linkedTargetCardIndex].duration =
      updatedLinkedTargetCard.duration;

    const indexToRemove = this.dataStore.cards.findIndex(
      (item) => item.id === this._selectedCard.id
    );
    this.dataStore.cards.splice(indexToRemove, 1);
    this.updateCardsList(this.dataStore.cards);
  }

  async getWashRequestByID(id: string) {
    const data = await this.api.GetWashRequestById(id);
    return data;
  }

  stopMissedNeedByTimeCard(
    cardId: string,
    stoppedAt: number,
    confinedSpaceEntryData,
    manualStartStopData?
  ) {
    return this.washListService
      .getReasonsForMissedNeedByTime()
      .then((reasons) => {
        const orderedReasons = reasons.sort((reason, otherReason) =>
          reason.order > otherReason.order ? 1 : -1
        );
        return this.showMissedNeedByTimeDialog({ data: orderedReasons });
      })
      .then((dialogResult) => {
        if (dialogResult) {
          const missedNeedByTimeReport = {
            stoppedAt,
            ...dialogResult,
          };
          return this.completeRequest(
            cardId,
            confinedSpaceEntryData,
            manualStartStopData,
            missedNeedByTimeReport
          );
        }
      });
  }

  validateIfWashIsLate(
    completeTime,
    cardId,
    item: Card | WashRequestDetail,
    confinedSpaceEntryData,
    manualStartStopData?
  ) {
    const stoppedAt = this.timeService.getTimestampInSecondsAsUTC(completeTime);
    let cardNeedByTime = item.needByTime;

    if (item instanceof Card && item.linked && item.linked.id === cardId) {
      cardNeedByTime = item.linked.needByTime;
    }

    const stoppedLate = stoppedAt > cardNeedByTime;

    if (stoppedLate) {
      return this.stopMissedNeedByTimeCard(
        cardId,
        stoppedAt,
        confinedSpaceEntryData,
        manualStartStopData
      );
    } else {
      return this.completeRequest(
        cardId,
        confinedSpaceEntryData,
        manualStartStopData
      );
    }
  }

  async sendDisagreementEmail(item: Card, status: string) {
    let user = this.authService?.user;

    const body = {
      card: item,
      user: user,
      cardStatus: status,
      currentTime: `${new Date().toLocaleString()} - Timezone: ${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
    };

    const path = '/send-disagreement-email';
    const httpOptions = { headers: this.defaultHeaders, body };

    return API.post('OnTraxAPI', path, httpOptions);
  }

  stopCard(cardId: string, item: Card | WashRequestDetail): Promise<any> {
    this.loadingDialogService.openDialog('Loading...');
    const stoppedAt = this.timeService.getNowAsUTC();

    return this.washListService._operators
      .pipe(
        take(1),
        switchMap(() => this.getWashRequestByID(cardId)),
        switchMap((washRequestResult) => {
          return this.showStartStopConfirmationDialog({
            operators: this.washListService.dataStore.operators,
            startTime: this.timeService.getTimestampInSecondsAsUTC(
              washRequestResult.startTime
            ),
            stoppedAt,
            currentCseData: {
              confinedEntry: washRequestResult.confinedEntry,
              workPerformedBy: washRequestResult.workPerformedBy,
              confinedEntryType: washRequestResult.confinedEntryType,
              files: washRequestResult.files,
            },
          });
        }),
        switchMap((startStopDialogResult) => {
          if (!startStopDialogResult) {
            return Promise.resolve(null);
          }

          const {
            startTime,
            completeTime,
            hasChangedStartStopTimes,
            confinedEntry,
            workPerformedBy,
            confinedEntryType,
            confinedEntryFiles,
          } = startStopDialogResult;

          const confinedSpaceEntryData = {
            confinedEntry,
            workPerformedBy,
            confinedEntryType,
            confinedEntryFiles,
          };

          if (hasChangedStartStopTimes) {
            return this.showManualStartStopReasonDialog().then(
              (reasonDialogResult) => {
                if (!reasonDialogResult) {
                  return Promise.resolve(null);
                }

                const { additionalComments, code } = reasonDialogResult;

                const manualStartStopData = {
                  startTime,
                  completeTime,
                  reason: {
                    additionalComments,
                    code,
                  },
                };

                return this.validateIfWashIsLate(
                  completeTime,
                  cardId,
                  item,
                  confinedSpaceEntryData,
                  manualStartStopData
                );
              }
            );
          } else {
            return this.validateIfWashIsLate(
              completeTime,
              cardId,
              item,
              confinedSpaceEntryData
            );
          }
        }),
        catchError((err) => {
          this.loadingDialogService.closeDialog();
          throw err;
        }),
        finalize(() => {
          this.loadingDialogService.closeDialog();
        })
      )
      .toPromise();
  }

  showMissedNeedByTimeDialog(data) {
    return this.missedNeedByTimeReportService.openDialog(data);
  }

  showStartStopConfirmationDialog(data) {
    this.loadingDialogService.closeDialog();
    return this.startStopConfirmationService.openDialog(data);
  }

  showManualStartStopReasonDialog() {
    return this.manualStartStopReasonService.openDialog();
  }

  showFlexInspectorFormToast(workOrderId) {
    const obPage = `${environment.openBravoBaseUrl}?tabId=AB955F8DD46E4F308199E14B13AECAE5&recordId=${workOrderId}`;
    const link = `<a class="action-button black-button flex-form-toast-button">Open Work Order in Etendo</a>`;

    const toastMessage = `
        Please click on this button to perform the Food Grade Flex Inspection in Etendo
        ${link}`;

    this.toastr
      .warning(toastMessage, '', {
        enableHtml: true,
        disableTimeOut: true,
        tapToDismiss: true,
      })
      .onTap.pipe()
      .subscribe(() => window.open(obPage));
  }
}
