import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../core/localstorage.service';
import { AuthService } from '../core/auth/auth.service';
@Component({
  selector: 'app-login-error',
  templateUrl: './login-error.component.html',
  styleUrls: ['./login-error.component.scss'],
})
export class LoginErrorComponent implements OnInit {
  private authType: string;
  username: string | null;
  userDate: Date = new Date();
  userTimezone: Date;
  isDateWrong: boolean = false;
  mailToLink: string;

  constructor(
    private localStorageService: LocalStorageService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    this.authType = this.localStorageService.getAuthType();

    await this.getUserInfos();

    await this.checkUserDate();

    this.getMailToLink(this.username);

    this.localStorageService.emptyLocalStorage();
  }

  async getUserInfos() {
    this.username = await this.authService.getKeycloakUsername();
  }

  getMailToLink(username) {
    this.mailToLink = 'mailto:ontrax-support@ciandt.com?subject=Login Problem';

    if (username) {
      this.mailToLink += `&body=I tried to log in as ${username}`;
    }
  }

  async checkUserDate() {
    // convert the response to date format
    this.userTimezone = new Date(
      String(await this.authService.getServerCurrentTimeUTC())
    );

    // convert the date to milliseconds and calculate the difefrence between both times
    const millisecondsDifference = Math.abs(
      this.userDate.getTime() - this.userTimezone.getTime()
    );

    // The login error occurs after a 5 minutes difference
    if (millisecondsDifference >= 5 * 60 * 1000) {
      this.isDateWrong = true;
    }
  }

  async logout() {
    this.localStorageService.emptyLocalStorage();
    await this.authService.logout(this.authType);
  }
}
