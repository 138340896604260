import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MainNavComponent } from 'src/app/global/main-nav/main-nav.component';
import { NcrIconComponent } from 'src/app/shared/ncr-icon/ncr-icon.component';
import { UserType } from '../menu/user-type.enum';
import { allTiles } from './tiles.config';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    MainNavComponent,
    NcrIconComponent,
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  tiles: any[];

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    const userType = this.authService.getUserType();

    if (userType == UserType.External) {
      const reportsTile = allTiles.find((t) => t.label == 'Reports');
      reportsTile.link = '/dashboard/wash-metrics';
    }

    this.tiles = allTiles.filter(
      (t) =>
        t.visibleByUserType == UserType.AllUserTypes ||
        t.visibleByUserType == userType
    );
  }
}
