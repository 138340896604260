import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { WashListComponent } from './wash-list/wash-list.component';
import { ReportsComponent } from './reports/reports.component';
import { LeanDailyManagementComponent } from './reports/lean-daily-management/lean-daily-management.component';
import { UserPreferencesComponent } from './notification-preferences/user/user-preferences.component';
import { NcrListComponent } from './non-conformity-report/ncr-list/ncr-list.component';
import { ExteriorWashOfferComponent } from './exterior-wash-offer/exterior-wash-offer.component';
import { WashMetricsComponent } from './reports/wash-metrics/wash-metrics.component';
import { OnTraxFeedComponent } from './home/ontrax-feed/ontrax-feed.component';
import { TerminalScorecardWashComponent } from './reports/terminal-scorecard-wash/terminal-scorecard-wash.component';
import { TerminalScorecardDepotComponent } from './reports/terminal-scorecard-depot/terminal-scorecard-depot.component';
import { TerminalScorecardIndustrialComponent } from './reports/terminal-scorecard-industrial/terminal-scorecard-industrial.component';
import { TerminalScorecardRailComponent } from './reports/terminal-scorecard-rail/terminal-scorecard-rail.component';
import { EhsWasteManagementTerminalComponent } from './reports/ehs-waste-management-terminal-view/ehs-waste-management-terminal-view.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { BulkUploadComponent } from './bulk-upload/bulk-upload.component';
import { DatalakeComponent } from './platform-other-tools/datalake/datalake.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { PermissionGuard } from '../core/auth/permission.guard';
import { DataFabricComponent } from './platform-other-tools/data-fabric/data-fabric.component';
import { RpaComponent } from './platform-other-tools/rpa/rpa.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      {
        path: OnTraxFeedComponent.route,
        component: OnTraxFeedComponent,
      },
      {
        path: AccessDeniedComponent.route,
        component: AccessDeniedComponent,
      },
      {
        path: BulkUploadComponent.route,
        component: BulkUploadComponent,
        canActivate: [PermissionGuard],
        canLoad: [PermissionGuard],
      },
      {
        path: DatalakeComponent.route,
        component: DatalakeComponent,
        canActivate: [PermissionGuard],
        canLoad: [PermissionGuard],
      },
      {
        path: DataFabricComponent.route,
        component: DataFabricComponent,
        canActivate: [PermissionGuard],
      },
      {
        path: RpaComponent.route,
        component: RpaComponent,
        canActivate: [PermissionGuard],
      },
      {
        path: 'wash-list/exterior-wash-offer',
        component: ExteriorWashOfferComponent,
        canLoad: [PermissionGuard],
        canActivate: [PermissionGuard],
      },
      {
        path: WashListComponent.route,
        canLoad: [PermissionGuard],
        canActivate: [PermissionGuard],
        component: WashListComponent,
      },
      {
        path: WashListComponent.route + '/:option',
        canLoad: [PermissionGuard],
        canActivate: [PermissionGuard],
        component: WashListComponent,
      },
      {
        path: 'schedule',
        loadChildren: () =>
          import('./schedule/schedule.module').then((m) => m.ScheduleModule),
        canActivate: [PermissionGuard],
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./settings/settings.module').then((m) => m.SettingsModule),
        canActivate: [PermissionGuard],
      },
      {
        path: 'reports',
        canLoad: [PermissionGuard],
        canActivate: [PermissionGuard],
        component: ReportsComponent,
      },
      {
        path: 'lean-daily-management',
        canLoad: [PermissionGuard],
        canActivate: [PermissionGuard],
        component: LeanDailyManagementComponent,
      },
      {
        path: 'wash-metrics',
        canLoad: [PermissionGuard],
        canActivate: [PermissionGuard],
        component: WashMetricsComponent,
      },
      {
        path: 'terminal-scorecard-wash',
        canLoad: [PermissionGuard],
        canActivate: [PermissionGuard],
        component: TerminalScorecardWashComponent,
      },
      {
        path: 'terminal-scorecard-depot',
        canLoad: [PermissionGuard],
        canActivate: [PermissionGuard],
        component: TerminalScorecardDepotComponent,
      },
      {
        path: 'terminal-scorecard-industrial',
        canLoad: [PermissionGuard],
        canActivate: [PermissionGuard],
        component: TerminalScorecardIndustrialComponent,
      },
      {
        path: 'terminal-scorecard-rail',
        canLoad: [PermissionGuard],
        canActivate: [PermissionGuard],
        component: TerminalScorecardRailComponent,
      },
      {
        path: 'ehs-waste-management-terminal-view',
        canLoad: [PermissionGuard],
        canActivate: [PermissionGuard],
        component: EhsWasteManagementTerminalComponent,
      },
      {
        path: 'support/terms-and-conditions',
        canLoad: [PermissionGuard],
        canActivate: [PermissionGuard],
        component: TermsConditionsComponent,
      },
      {
        path: NcrListComponent.route,
        loadChildren: () =>
          import('./non-conformity-report/non-conformity-report.module').then(
            (m) => m.NonConformityReportModule
          ),
      },
      {
        path: 'shift-logs',
        loadChildren: () =>
          import('./shift-logs/shift-logs.module').then(
            (m) => m.ShiftLogsModule
          ),
        canActivate: [PermissionGuard],
      },
      {
        path: 'settings/business-partner-notification',
        loadChildren: () =>
          import(
            './notification-preferences/business-partner/business-partner.module'
          ).then((m) => m.BusinessPartnerModule),
        canActivate: [PermissionGuard],
      },
      {
        path: 'settings/user-notification',
        component: UserPreferencesComponent,
        canLoad: [PermissionGuard],
        canActivate: [PermissionGuard],
      },
      {
        path: '**',
        redirectTo: '/home',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
