import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import * as menuConfig from '../../dashboard/menu/menu.config';
import { RoleName } from 'src/app/dashboard/menu/role-name.enum';
import { MenuOption, OptionConfig } from 'src/app/dashboard/menu/menu.model';
import { WashListComponent } from 'src/app/dashboard/wash-list/wash-list.component';
const sectionDict = menuConfig.getSectionDict();

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard {
  constructor(
    private authService: AuthService,
    protected router: Router
  ) {}

  isSectionAvailable(authorizedRoles: RoleName[], currentRole: string) {
    if (authorizedRoles.includes(RoleName.AllRoles)) {
      return true;
    }
    const availableRole = authorizedRoles.find((role) =>
      currentRole.includes(role)
    );
    return availableRole !== undefined;
  }

  isUrlAvailableForOptions(
    options: OptionConfig[],
    path: string[],
    currentRole: string
  ) {
    for (const option of options) {
      const splittedOptions = option.value.split('/');
      const matchUrl = splittedOptions.every((item) => path.includes(item));
      const isAvailable = this.isSectionAvailable(
        option.authorizedRoles,
        currentRole
      );
      if (matchUrl && isAvailable) {
        return true;
      }
    }
    return false;
  }

  isUrlAvailable(
    currentRole: string,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (!currentRole) {
      return false;
    }

    const obRedirectBreakpopint = '#';
    const queryParamsBreakpoint = '?';
    const pathBreakpoint = '/';
    const url = decodeURIComponent(state?.url || '');
    let splittedUrl = url.split(pathBreakpoint);

    if (url.includes(obRedirectBreakpopint)) {
      splittedUrl = url.includes(obRedirectBreakpopint)
        ? url.split(obRedirectBreakpopint)[0].split(pathBreakpoint)
        : url.split(pathBreakpoint);
    }

    if (url.includes(queryParamsBreakpoint)) {
      splittedUrl = url.includes(queryParamsBreakpoint)
        ? url.split(queryParamsBreakpoint)[0].split(pathBreakpoint)
        : url.split(pathBreakpoint);
    }

    for (const path of splittedUrl) {
      if (!sectionDict[path]) {
        continue;
      }

      const section = sectionDict[path];

      if (
        section?.authorizedRoles &&
        !section?.options &&
        this.isSectionAvailable(section?.authorizedRoles, currentRole)
      ) {
        return true;
      }

      if (
        !section?.authorizedRoles &&
        section?.options &&
        this.isUrlAvailableForOptions(
          section?.options,
          splittedUrl,
          currentRole
        )
      ) {
        return true;
      }

      if (!section?.authorizedRoles && section?.options) {
        const lastStringOfUrl = splittedUrl[splittedUrl.length - 1];
        const isLastStringValid =
          lastStringOfUrl.includes(MenuOption.Ncr) ||
          lastStringOfUrl.includes(WashListComponent.route);
        let hasFullReportAccess = this.authService?.user?.hasFullReportAccess;

        if (
          state.url.includes(WashListComponent.route) &&
          this.isUrlAvailableForOptions(
            section?.options,
            [...splittedUrl, MenuOption.Draft],
            currentRole
          ) &&
          isLastStringValid
        ) {
          return true;
        }

        if (state.url.includes(MenuOption.Ncr) && hasFullReportAccess) {
          return true;
        }

        if (
          state.url.includes(MenuOption.Ncr) &&
          this.isUrlAvailableForOptions(
            section?.options,
            [...splittedUrl, MenuOption.NcrCompleted],
            currentRole
          ) &&
          isLastStringValid
        ) {
          return true;
        }
      }
    }
    return false;
  }

  canLoad(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canLoadOrActivate(route, state);
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.canLoadOrActivate(route, state);
  }

  private logUnauthorizedPageAccess(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const logData = {};
    logData['route'] = {
      fromUrl: state.url,
      data: route.data,
      params: route.params,
      queryParams: route.queryParams,
    };
    logData['user'] = {
      ...this.authService?.user,
      ...this.authService?.getUserSessionExpirationTime(),
    };

    this.authService?.logUnauthorizedPageAccess(logData);
  }

  async canLoadOrActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    let currentRole = this.authService?.user?.currentRole;

    if (!currentRole) {
      await this.authService.getProfile();
      currentRole = this.authService?.user.currentRole;
    }

    if (this.isUrlAvailable(currentRole, route, state)) {
      return true;
    }

    this.logUnauthorizedPageAccess(route, state);

    this.router.navigate(['/dashboard/access-denied'], {
      queryParams: { from: state.url },
    });

    return false;
  }
}
