import {
  Component,
  OnInit,
  Input,
  ViewContainerRef,
  OnDestroy,
  ElementRef,
  ViewChild,
  SimpleChange,
  ChangeDetectorRef,
  AfterContentChecked,
} from '@angular/core';
import {
  slotHeight,
  cardPaddingTop,
  timeIntervalInMinutes,
  Card,
  defaultTimeSize,
  heightBetweenShifts,
} from '../schedule.model';
import { ScheduleService } from '../schedule.service';
import { ToastrService } from 'ngx-toastr';
import { WashDetailsOverlayComponent } from '../wash-details-overlay/wash-details-overlay.component';
import { DialogConfirmationService } from 'src/app/shared/dialog-confirmation/dialog-confirmation.service';
import { DialogType } from 'src/app/shared/dialog-confirmation/dialog-confirmation.model';
import { TimeService } from 'src/app/core/time.service';
import { AgendaBay } from '../agenda/agenda.model';
import { WashRequestStatus } from '../../wash-list/wash-list.model';
import { ComponentType } from '@angular/cdk/portal';
import { SatPopover } from '@ncstate/sat-popover';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit, OnDestroy, AfterContentChecked {
  canStart = false;
  canStartLinked = false;
  cantStartReason: string;
  closeTimeout: any;
  cantStartLinkedReason: string;
  private extraSpace = 10;
  private inProgressHeight = 180;
  private interval: any;
  isSelectedCard = false;
  showSpecialInstructionsInPanelMode = false;
  private isProcessingCardLinkAction = false;
  specialInstPanelModeHeightDiscount: number;
  statusEmail: { [key: string]: unknown } = {};
  isMobile: boolean;

  @Input() isInProgressBar = false;
  @Input() item: Card;
  @Input() isMoveMode: boolean;
  @Input() isBayOccupied: boolean;
  @Input() bay: AgendaBay;

  @Input() panelMode: string;
  @ViewChild('cardBody', { static: false }) cardBody: ElementRef<HTMLElement>;
  specialInstrucHeightDiscount: number;
  @ViewChild('startP') startP: SatPopover;

  get generalClass() {
    if (!this.item) {
      return 'no-wash';
    }
    if (this.item.cardType === 'downtime') {
      return 'downtime';
    }
    if (this.item.cardType === 'walkup') {
      return 'walkup';
    }
    if (this.item.cardType === 'default' || this.item.cardType === 'progress') {
      return 'default';
    }
    return '';
  }

  get generalId() {
    let id = `card-${this.item.containerNumber}`;
    if (this.item.cardType === 'progress' && !this.isInProgressBar) {
      id += `-shadow`;
    }
    return id;
  }

  get showRemove() {
    return ['downtime', 'walkup'].includes(this.generalClass);
  }

  get canBeLinked() {
    if (
      this.isMoveMode &&
      this.scheduleService.selectedCard.cardType !== 'walkup' &&
      this.scheduleService.selectedCard.cardType !== 'downtime'
    ) {
      return (
        this.scheduleService.selectedCard.compatibleBays.includes(
          this.bay.id
        ) &&
        !this.item.linked &&
        !this.isSelectedCard &&
        this.item.cardType !== 'walkup' &&
        this.bay.dualType
      );
    }
    return false;
  }

  /**
   * Must be used only in move mode
   */
  get showMove() {
    if (this.item.cardType === 'progress') {
      return false;
    }
    return ['downtime', 'walkup', 'default'].includes(this.generalClass);
  }

  /**
   * Must be used only in move mode
   */
  get showCancelMove() {
    if (this.item.cardType === 'progress') {
      return false;
    }
    return (
      this.scheduleService.selectedCard &&
      this.scheduleService.selectedCard.id === this.item.id
    );
  }

  get showActions() {
    return ['default'].includes(this.generalClass) && !this.inProgressShadow;
  }

  get linkedCardProgressClass() {
    if (!this.item.linked) {
      return '';
    }
    if (
      this.item.linked.flagMissingNeedByTime ||
      this.item.linked.flagBeforeArriving
    ) {
      return 'red';
    }
    if (
      this.item.linked.flagAlmostOnNeedByTime ||
      this.item.linked.flagMissingVelocitySLA
    ) {
      return 'orange';
    }
    return 'green';
  }

  get progressClass() {
    if (!this.item) {
      return '';
    }
    if (this.red) {
      return 'red';
    }
    if (this.orange) {
      return 'orange';
    }
    return 'green';
  }

  get messageTooltip() {
    if (!this.item) {
      return '';
    }
    if (this.inProgressShadow) {
      return 'This card is in progress, go to the In Progress section for more actions.';
    }
    if (this.red) {
      return 'Estimated time to complete the wash request exceeds the need by time. Or, it is scheduled before the expected arrival time.';
    }
    if (this.orange) {
      return (
        'The card is within 1 hour or less prior to the need by time. Or, the estimated time to ' +
        'complete it exceeds the target velocity set for the terminal.'
      );
    }
    return (
      'The card is comfortably scheduled (or in progress) between the arrival time of the trailer' +
      'and the need by time requested by the dispatcher.'
    );
  }

  get red() {
    return this.item.flagMissingNeedByTime || this.item.flagBeforeArriving;
  }

  get orange() {
    return this.item.flagAlmostOnNeedByTime || this.item.flagMissingVelocitySLA;
  }

  get isSelected() {
    // Empty slot (in progress area) is never selected
    if (!this.item) {
      return false;
    }
    return (
      this.scheduleService.selectedCard &&
      this.scheduleService.selectedCard.id === this.item.id
    );
  }

  get cardSize() {
    if (
      !this.item ||
      (this.item.cardType === 'progress' && !this.inProgressShadow)
    ) {
      return this.inProgressHeight;
    }

    let timeSize = defaultTimeSize;
    if (this.item.duration >= 30) {
      timeSize = Math.ceil(this.item.duration / timeIntervalInMinutes);
    }

    return (
      timeSize * (slotHeight + cardPaddingTop) - // time size * { slot heigh + padding top }
      (cardPaddingTop + this.extraSpace) +
      (this.item.isATwoDaysSchedule ? heightBetweenShifts : 0)
    ); // Remove space from bottom: ;
  }

  get showSpecialInstructions() {
    return (
      this.item &&
      this.item.specialInstructions &&
      this.item.cardType === 'default' &&
      !this.item.linked
    );
  }

  get showServiceType() {
    if (this.item && this.item.linked) {
      if (
        this.item.cardType === 'progress' ||
        this.item.linked.cardType === 'progress' ||
        !this.item.serviceType ||
        this.item.duration <= 30
      ) {
        return false;
      } else {
        return true;
      }
    } else if (this.item && !this.item.linked) {
      if (this.item.serviceType) {
        return true;
      } else {
        return false;
      }
    }
  }

  get showLastContained() {
    if (this.item && this.item.linked) {
      if (
        this.item.cardType === 'progress' ||
        this.item.linked.cardType === 'progress' ||
        !this.item.lastContained ||
        this.item.duration < 40
      ) {
        return false;
      } else {
        return true;
      }
    } else if (this.item && !this.item.linked) {
      if (this.item.lastContained) {
        return true;
      } else {
        return false;
      }
    }
  }

  get isMiniumTimeAndLinkedCard() {
    return this.item.linked && this.item.duration <= 30;
  }

  get isMediumTimeAndLinkedCard() {
    return this.item.linked && this.item.duration <= 40;
  }

  get inProgressShadow() {
    return this.item.cardType === 'progress' && !this.isInProgressBar;
  }

  get cantStopReason() {
    return this.item.availableActionsStop
      ? 'Finish the cleaning and remove the tank from the bay'
      : 'You can only stop a request that is in progress';
  }

  get canStop() {
    return this.item.availableActionsStop;
  }

  constructor(
    private scheduleService: ScheduleService,
    public viewContainerRef: ViewContainerRef,
    private dialogConfirmationService: DialogConfirmationService,
    private timeService: TimeService,
    private toastr: ToastrService,
    private breakpointObserver: BreakpointObserver,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.checkIfCanStart();
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe((result) => {
      this.isMobile = result.matches;
    });
    if (
      this.item &&
      (this.item.cardType === 'default' || this.item.cardType === 'progress')
    ) {
      this.interval = setInterval(this.checkIfCanStart.bind(this), 1000 * 60);
    }

    if (this.item && this.item.id === this.scheduleService.overlayCardId) {
      this.openOverlay(this.scheduleService.overlayContainer, this.item.id);
      this.toastr.success('', 'Schedule was updated!');
    } else if (
      this.item &&
      this.item.linked &&
      this.item.linked.id === this.scheduleService.overlayCardId
    ) {
      this.openOverlay(
        this.scheduleService.overlayContainer,
        this.item.linked.id
      );
      this.toastr.success('', 'Schedule was updated!');
    }

    this.showSpecialInstructionsInPanelMode =
      this.item?.specialInstructions &&
      this.item?.cardType === 'default' &&
      ((this.item?.duration > 50 && this.item?.servicePlanName?.length < 5) ||
        this.item?.duration > 60) &&
      !this.item?.linked;

    // #TODO allow this calculation css-only, maybe optmizing grid layout usage.
    const baseLineHeight = 24;
    const servicePlanWordsHeight =
      this.item && this.item?.servicePlanName
        ? this.item?.servicePlanName?.length * baseLineHeight
        : 0;
    const cardsDefaultElementsHeight = 3 * baseLineHeight;
    const iconBarHeight = 30;
    const headerAndFooterHeight = 100;
    this.specialInstrucHeightDiscount =
      cardsDefaultElementsHeight + headerAndFooterHeight + iconBarHeight;

    const baseLineHeightPanelMode = 20;
    const servicePlanWordsHeightPanelMode =
      this.item && this.item?.servicePlanName
        ? this.item?.servicePlanName?.length * baseLineHeightPanelMode
        : 0;
    const cardsDefaultElementsHeightPanelMode = 3 * baseLineHeightPanelMode;
    this.specialInstPanelModeHeightDiscount =
      servicePlanWordsHeightPanelMode +
      cardsDefaultElementsHeightPanelMode +
      headerAndFooterHeight +
      iconBarHeight;
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.scheduleService.detachOverlay();
  }

  ngAfterContentChecked() {
    this.changeDetector.detectChanges();
  }

  openWashDetails(linked: string) {
    this.openOverlay(WashDetailsOverlayComponent, linked);
  }

  openOperatorsOverlay(cardId: string) {
    if (this.item.id === cardId) {
      this.scheduleService.displayOperatorsDialog(
        { card: this.item },
        this.bay.cardInProgress
      );
    } else {
      this.scheduleService.displayOperatorsDialog({ card: this.item.linked });
    }
  }

  async emitDisagreementData(item: Card, status: string) {
    this.statusEmail[item.id] = 'loading';
    try {
      await this.scheduleService.sendDisagreementEmail(item, status);
    } catch (error) {
      this.statusEmail[item.id] = 'error';
    } finally {
      this.statusEmail[item.id] = 'sent';
    }
  }

  private openOverlay<T>(overlayComponent: ComponentType<T>, cardId: string) {
    this.scheduleService.overlayContainer = overlayComponent;
    this.scheduleService.overlayCardId = cardId;

    if (this.item.id === cardId) {
      this.scheduleService.displayOverlay(
        { card: this.item, isBayOccupied: this.isBayOccupied },
        this.viewContainerRef,
        overlayComponent,
        this.bay.cardInProgress
      );
    } else {
      this.scheduleService.displayOverlay(
        { card: this.item.linked, isBayOccupied: this.isBayOccupied },
        this.viewContainerRef,
        overlayComponent
      );
    }
  }

  initMove() {
    if (this.item.ectCustomerHasViewed) {
      this.toastr.warning(
        'The Completion Time for this request was already viewed by a customer.' +
          ' In case of change of the wash completion time, a message with' +
          ' the new date/time will be automatically sent to the customer.'
      );
    }
    this.isSelectedCard = true;
    this.scheduleService.selectCard(this.item);
  }

  cancelMove() {
    this.isSelectedCard = false;
    this.scheduleService.selectCard(null);
  }

  start(cardId: string) {
    this.openOperatorsOverlay(cardId);
  }

  stop(cardId: string) {
    this.scheduleService
      .stopCard(cardId, this.item)
      .then((washRequestUpdatedData) => {
        if (
          !washRequestUpdatedData ||
          !washRequestUpdatedData.flexInspection ||
          !washRequestUpdatedData.flexInspection.hasFlexInspection
        ) {
          return;
        }
        this.scheduleService.showFlexInspectorFormToast(
          washRequestUpdatedData.flexInspection.workOrderId
        );
      });
  }

  hold(cardId: string, isHeelPreApproved: boolean) {
    let disclaimer: string;
    let type: DialogType;

    if (isHeelPreApproved) {
      disclaimer =
        'This request was submitted with the Heel Handling already approved';
      type = DialogType.withWarning;
    }

    this.showDialogConfirmation(
      'Put request on hold?',
      'hand-paper',
      'Yes',
      disclaimer,
      type
    ).then((dialogResult) => {
      if (dialogResult) {
        this.scheduleService.hold(cardId);
      }
    });
  }

  pause(cardId: string) {
    this.scheduleService.pause(cardId);
  }

  onMouseLeave(): void {
    this.closeTimeout = setTimeout(() => {
      if (this.startP.isOpen && !this.isMobile) {
        this.startP.close();
      }
    }, 400);
  }

  onMouseEnter(): void {
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
      this.closeTimeout = null;
    }
  }

  async cancelMaintenance(cardId: string) {
    this.scheduleService.cancelMaintenance(cardId);
  }

  showDialogConfirmation(
    question,
    icon,
    action,
    text?,
    type = DialogType.default
  ) {
    return this.dialogConfirmationService.openDialog({
      title: question,
      icon,
      action,
      text,
      type,
    });
  }

  private checkIfCanStart() {
    // First condition is that the backend must inform if it can start
    this.canStart =
      this.item &&
      this.item.availableActionsStart &&
      // Then, we validate if either is already in bay or if scheduled and bay is not occupied
      (this.item.cardType === 'progress' || !this.isBayOccupied) &&
      // And the card start time must be less than or equal 1h ahead of current time
      this.isInTimeToStart();

    if (!this.canStart) {
      this.getCantStartReason();
    } else {
      this.cantStartReason = '';
    }

    if (this.item && this.item.linked) {
      this.canStartLinked =
        this.item.linked &&
        this.item.linked.availableActionsStart &&
        // Then, we validate if either is already in bay or if scheduled and bay is not occupied
        (this.item.linked.cardType === 'progress' || !this.isBayOccupied) &&
        // And the card start time must be less than or equal 1h ahead of current time
        this.isInTimeToStart();

      if (!this.canStartLinked) {
        this.getCantStartLinkedReason();
      } else {
        this.cantStartLinkedReason = '';
      }
    }
  }

  private getCantStartLinkedReason() {
    if (
      this.item.linked &&
      this.item.linked.status === WashRequestStatus.Started
    ) {
      this.cantStartLinkedReason = 'Wash is already in progress';
    } else if (this.item.linked && !this.item.linked.availableActionsStart) {
      this.cantStartLinkedReason =
        'Sales Order not converted to Work Order on Etendo. Wait for automated conversion or go to Etendo for manual conversion.';
    } else if (this.isBayOccupied) {
      this.cantStartLinkedReason =
        'There is already a wash in progress in the bay. Finish the card already In Progress or move the card to another bay.';
    } else if (this.item.linked && !this.isInTimeToStart()) {
      this.cantStartLinkedReason =
        'Scheduled request start time is more than 1 hour from now. Move the card to an earlier slot.';
    }
  }

  private getCantStartReason() {
    if (this.item && this.item.status === WashRequestStatus.Started) {
      this.cantStartReason = 'Wash is already in progress';
    } else if (this.item && !this.item.availableActionsStart) {
      this.cantStartReason =
        'Sales Order not converted to Work Order on Etendo. Wait for automated conversion or go to Etendo for manual conversion.';
    } else if (this.isBayOccupied) {
      this.cantStartReason =
        'There is already a wash in progress in the bay. Finish the card already In Progress or move the card to another bay.';
    } else if (this.item && !this.isInTimeToStart()) {
      this.cantStartReason =
        'Scheduled request start time is more than 1 hour from now. Move the card to an earlier slot.';
    }
  }

  private isInTimeToStart() {
    const now = this.timeService.getNowAsUTC();
    const diff = this.item.startTime - now;

    const hours = diff / (60 * 60);
    return hours <= 1;
  }

  linkCards(targetCard: Card) {
    if (!this.isProcessingCardLinkAction) {
      this.scheduleService.linkCards(targetCard).subscribe(
        (linkedTargetCard: any) => {
          this.scheduleService.updateCardsOnLink(linkedTargetCard);
          this.isSelectedCard = false;
          this.cancelMove();
        },
        (response) => {
          this.isSelectedCard = false;
          this.cancelMove();
          const message =
            response.status === 400
              ? 'Link operation not allowed'
              : 'There is no available agenda space to link cards';
          this.toastr.error('', message);
        }
      );
    }
  }

  getOnHoldTooltip(): string {
    if (this.item.createdByEtendo) {
      return 'This wash request was created on Etendo. You can’t put it on hold.';
    }
    if (this.item.availableActionsHold) {
      return 'Hold the cleaning and remove the tank from the bay';
    }
    return 'You can only hold a request that is in progress';
  }
}
