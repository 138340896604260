import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

export const homeRedirectGuard = async (): Promise<boolean | UrlTree> => {
  const deviceDetector = inject(DeviceDetectorService);
  const router = inject(Router);

  if (deviceDetector.isMobile()) {
    return true;
  }

  return router.parseUrl('/dashboard/feed');
};
